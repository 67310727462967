import React from 'react'

export const Header = () => {

  return (
    <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div class="relative flex items-center justify-between">
        <a
          href="/"
          aria-label="Company"
          title="Company"
          class="inline-flex items-center"
        >
          <svg className='w-32 md:w-64 fill-rosso' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 613.39 91.89">
              <path 
                  d="M234.55,18.09h6.23l23.73,55.82h-7.72l-5.68-13.72H223.2l-5.75,13.72H210Zm3,8.51h-.16L225.8,54h22.79Z" />
              <path 
                  d="M311,29.6a15.92,15.92,0,0,0-6.23-5.08,18.35,18.35,0,0,0-7.72-1.7,19.36,19.36,0,0,0-8.56,1.89,21.4,21.4,0,0,0-6.74,5.09,23.37,23.37,0,0,0-4.41,7.41A24.69,24.69,0,0,0,275.79,46a25.61,25.61,0,0,0,1.58,9.11,22.71,22.71,0,0,0,4.37,7.33,20.56,20.56,0,0,0,6.7,4.93,21.66,21.66,0,0,0,17.78-.2,20.71,20.71,0,0,0,7-5.8l5,4.18A22.9,22.9,0,0,1,309.1,73a29.27,29.27,0,0,1-12,2.37,28.4,28.4,0,0,1-11.2-2.21,27.57,27.57,0,0,1-9-6.11,28.22,28.22,0,0,1-6-9.26A31,31,0,0,1,268.69,46a31.46,31.46,0,0,1,2.09-11.51,27.73,27.73,0,0,1,26.3-17.82,29.69,29.69,0,0,1,10.92,2,20.2,20.2,0,0,1,8.79,6.7Z" />
              <path  d="M327.43,18.09h35.08v6.15H334.05v17.5h26.57v6.15H334.05V67.76h29.88v6.15h-36.5Z" />
              <path 
                  d="M443.56,70.13a39.45,39.45,0,0,1-10.13,3.94,46.53,46.53,0,0,1-10.76,1.26,32.57,32.57,0,0,1-12-2.13,28,28,0,0,1-9.43-6,26.93,26.93,0,0,1-6.18-9.27A31.08,31.08,0,0,1,392.87,46a30.1,30.1,0,0,1,2.32-12,27.18,27.18,0,0,1,6.43-9.23,29.63,29.63,0,0,1,9.54-5.95,31.94,31.94,0,0,1,11.75-2.13,33.87,33.87,0,0,1,10.6,1.66A22.67,22.67,0,0,1,442.62,24l-5,5.21a18.93,18.93,0,0,0-6.9-4.73,21.75,21.75,0,0,0-8.32-1.66,22.62,22.62,0,0,0-8.91,1.74A21.49,21.49,0,0,0,401.66,36.7,24.93,24.93,0,0,0,400,46a24.66,24.66,0,0,0,1.7,9.22,22.3,22.3,0,0,0,4.73,7.38,21.31,21.31,0,0,0,7.29,4.85,24.78,24.78,0,0,0,9.38,1.73,32.71,32.71,0,0,0,7.1-.83,28.3,28.3,0,0,0,6.78-2.4V48.84H424.09V42.69h19.47Z" />
              <path 
                  d="M456.34,45.76c0-1.68-.06-3.25-.16-4.73s-.16-2.81-.16-4h5.83c0,1,0,2,.08,3s.08,2,.08,3.07h.16a10.71,10.71,0,0,1,1.77-2.6,13.15,13.15,0,0,1,2.72-2.25,14.72,14.72,0,0,1,7.81-2.16,10.64,10.64,0,0,1,1.26.07,5.83,5.83,0,0,1,1.26.32l-.39,6.23a11.32,11.32,0,0,0-3.31-.47c-3.79,0-6.53,1.22-8.24,3.66s-2.56,5.8-2.56,10v18h-6.15Z" />
              <path 
                  d="M482,55.46a20,20,0,0,1,1.5-7.88,18.4,18.4,0,0,1,4.1-6.11,18,18,0,0,1,6.23-4,22.57,22.57,0,0,1,15.76,0,18,18,0,0,1,6.23,4,18.55,18.55,0,0,1,4.1,6.11,21.47,21.47,0,0,1,0,15.77,18.66,18.66,0,0,1-4.1,6.11,18.34,18.34,0,0,1-6.23,4,22.57,22.57,0,0,1-15.76,0,18.06,18.06,0,0,1-10.33-10.09A20.1,20.1,0,0,1,482,55.46Zm6.62,0a15.46,15.46,0,0,0,.91,5.4,12.46,12.46,0,0,0,6.78,7.26,14.28,14.28,0,0,0,10.8,0,12.46,12.46,0,0,0,6.78-7.26,16.48,16.48,0,0,0,0-10.8,12.4,12.4,0,0,0-6.78-7.25,14.16,14.16,0,0,0-10.8,0,12.4,12.4,0,0,0-6.78,7.25A15.54,15.54,0,0,0,488.58,55.46Z" />
              <path 
                  d="M563.09,65.16c0,1.68,0,3.26.15,4.73s.16,2.81.16,4h-5.83c0-1,0-2-.08-3s-.08-2-.08-3.07h-.16a10.71,10.71,0,0,1-1.77,2.6,12.89,12.89,0,0,1-2.72,2.25,14.72,14.72,0,0,1-3.55,1.58,14.87,14.87,0,0,1-4.26.59,16.07,16.07,0,0,1-6.26-1.11,11.63,11.63,0,0,1-4.38-3.07,12.39,12.39,0,0,1-2.56-4.77,21.54,21.54,0,0,1-.83-6.11V37h6.15V59.17a11.78,11.78,0,0,0,2,7.33q2,2.69,6.38,2.68a11.92,11.92,0,0,0,5.17-1,9.17,9.17,0,0,0,3.55-2.91,13.11,13.11,0,0,0,2-4.5,23.33,23.33,0,0,0,.67-5.75V37h6.15Z" />
              <path 
                  d="M574.44,37h6.15v5.36h.16a18.32,18.32,0,0,1,5.87-4.65A16.44,16.44,0,0,1,594,36.07a21,21,0,0,1,7.93,1.45A17.79,17.79,0,0,1,608,41.59,18.76,18.76,0,0,1,612,47.73a21.75,21.75,0,0,1,0,15.46,19.32,19.32,0,0,1-3.9,6.15,17.79,17.79,0,0,1-5.88,4.06,18.2,18.2,0,0,1-7.25,1.46,17.11,17.11,0,0,1-8.71-2.17,14.05,14.05,0,0,1-5.48-5.32h-.16V91.89h-6.15Zm6.15,18.45a15.46,15.46,0,0,0,.91,5.4,12.46,12.46,0,0,0,6.78,7.26,14.28,14.28,0,0,0,10.8,0,12.46,12.46,0,0,0,6.78-7.26,16.48,16.48,0,0,0,0-10.8,12.4,12.4,0,0,0-6.78-7.25,14.16,14.16,0,0,0-10.8,0,12.4,12.4,0,0,0-6.78,7.25A15.54,15.54,0,0,0,580.59,55.46Z" />
              <polygon  points="67.67 91.83 78.23 91.83 78.23 81.27 67.66 81.27 67.67 91.83" />
              <polygon 
                  points="134.96 10.55 181.1 10.55 181.1 0 124.35 0 124.35 10.55 78.23 10.55 78.23 0 67.67 0 0 91.89 10.55 91.89 67.57 10.55 67.57 11.33 67.57 10.56 78.23 10.56 78.23 81.27 124.35 81.27 124.35 91.83 181.1 91.83 181.1 81.27 134.96 81.27 134.96 51.19 181.14 51.19 181.14 40.64 134.96 40.64 134.96 10.55" />
          </svg>
        </a>
        <ul class="flex items-center space-x-8 lg:flex">
          <li>
            <a
              href="/about"
              aria-label="Our product"
              title="Our product"
              class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              会社概要
            </a>
          </li>
          <li>
            <a
              href="/recruit"
              aria-label="Our product"
              title="Our product"
              class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              採用情報
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};