import React from 'react'
import { Feature } from '../components/about/Feature'
import { FirstView } from '../components/about/FirstView'
import Layout from '../components/layout'
import Seo from '../components/seo'

const AboutPage = ({ data, location }) => {
    return (
        <Layout title="ACE Group" location={location}>
            <Seo title="会社概要" />
            <FirstView title='会社概要'/>
            <Feature/>
        </Layout>
    )
}

export default AboutPage