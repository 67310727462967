import React,{useState} from 'react'

const Data = [
    {
        title:'週休2日　３０万円～　軽貨物ドライバー大募集！！',
        detail:'センターに届いた荷物をお客様の指定した場所まで運ぶ簡単なお仕事です！\n研修制度もあるので初心者の方でも安心して働くことが可能です！\n自分だけの空間でお仕事したい方、高収入を目指したい方には是非オススメのお仕事になります。\n仕事エリアは愛知、岐阜エリアを設けております。\nご自宅から近いエリアでもお仕事が可能です。\n事業拡大につき人員大募集を行っております。\nお気軽にご連絡ください！',
        qualification:['既卒・第二新卒歓迎','英語力不問','未経験OK','学歴不問','要普通自動車免許（AT限定可）'],
        location:'愛知県、岐阜県全域（ご自宅から近い場所を提案します）\nご自宅から近いエリアで勤務して頂きます！\n基本的に事務所まで行くことはありません！',
        time: 'シフト制\n早く配達が終わればお昼過ぎに帰れることもあります！\n稼ぎたい方はフルタイムで稼いでいます！\n※契約内容により異なります。',
        salary:'月給　300,000円　～　700,000円　（※想定年収　3,600,000円　～　10,000,000円）\n勤続手当や役職手当なども充実しております！',
        holiday:'週休2日制（シフト制）',
        other:'服装自由'
    },
    {
        title:'クルマ好き歓迎！自動車買取販売営業募集！！',
        detail:'クルマの買取、販売スタッフを募集しております。\n特に必要資格はありません。\n車に興味のある方大歓迎！',
        qualification:['未経験OK','学歴不問','要普通自動車免許（AT限定可）'],
        location:'愛知県名古屋市中川区',
        time: 'シフト制',
        salary:'月給　200,000円　～　500,000円　（※想定年収　2,400,000円　～　6,000,000円）',
        holiday:'シフト制',
        other:'服装自由'
    },
]

const Items = ({title,children}) => {
    return(
        <>
          <p
            aria-label="Article"
            title="Jingle Bells"
            className="inline-block mb-3 text-xl font-bold leading-5 text-black transition-colors duration-200 text-rosso"
          >
           {title}
          </p>
          <p className="text-gray-700">
            {children}
          </p>
        </>
    )  
}

const Detail = ({data}) => {
    return(
        <div className="py-16 mx-auto lg:py-20">
            <div className="flex flex-col items-start md:flex-row sm:mx-auto">
                <div className='w-full'>
                    <div>
                        <h3 className="mb-4 font-sans text-2xl font-bold tracking-tight text-gray-900 ">
                            {data.title}
                        </h3>
                    </div>
                    <div className='border-l-4 shadow-sm border-rosso'>
                        <div className='h-full px-5 border border-gray border-l-0 rounded-r'>
                            <div className='py-6'>
                                <h4 className='text-rosso text-xl font-semibold'>
                                    仕事内容
                                </h4>
                                <p className="text-base text-gray-700 md:text-lg">
                                    {data.detail}
                                </p>
                            </div>
                            <div className='pb-6'>
                                <h4 className='text-rosso text-xl font-semibold'>
                                    勤務地
                                </h4>
                                <p className="text-base text-gray-700 md:text-lg">
                                    {data.location}
                                </p>
                            </div>
                            <div className='pb-6'>
                                <h4 className='text-rosso text-xl font-semibold'>
                                    給与
                                </h4>
                                <p className="text-base text-gray-700 md:text-lg">
                                    {data.salary}
                                </p>
                            </div>
                            <div className='pb-6'>
                                <h4 className='text-rosso text-xl font-semibold'>
                                    勤務時間
                                </h4>
                                <p className="text-base text-gray-700 md:text-lg">
                                    {data.time}
                                </p>
                            </div>
                            <div className='pb-6'>
                                <h4 className='text-rosso text-xl font-semibold'>
                                    休日休暇
                                </h4>
                                <p className="text-base text-gray-700 md:text-lg">
                                    {data.holiday}
                                </p>
                            </div>
                            <div className='pb-6'>
                                <h4 className='text-rosso text-xl font-semibold'>
                                    応募資格
                                </h4>
                                <p className="text-base text-gray-700 md:text-lg">
                                    {data.qualification.map((item,i)=>{
                                        return <span className='block my-2' key={i}>{item}</span>
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Faq = () => {
    const [selectItem, setSelectItem] = useState(0)
    return (
        <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <h2 class="max-w-lg mb-12 font-sans text-center text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">募集職種</h2>
            <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                {selectItem === 0 ?(
                    <button className="p-8 bg-white border border-rosso rounded shadow-sm hover:border-rosso" onKeyDown={()=>setSelectItem(0)} onClick={()=>setSelectItem(0)}>
                        <Items title='軽貨物ドライバー'>
                            センターに届いた荷物をお客様の指定した場所まで運ぶ簡単なお仕事です
                        </Items>
                    </button>
                ):(
                    <button className="p-8 bg-white border rounded shadow-sm hover:border-rosso" onKeyDown={()=>setSelectItem(0)} onClick={()=>setSelectItem(0)}>
                        <Items title='軽貨物ドライバー'>
                            センターに届いた荷物をお客様の指定した場所まで運ぶ簡単なお仕事です
                        </Items>
                    </button>
                )}
                {selectItem === 1 ?(
                    <button className="p-8 bg-white border rounded shadow-sm border-rosso hover:border-rosso" onKeyDown={()=>setSelectItem(1)} onClick={()=>setSelectItem(1)}>
                        <Items title='自動車買取販売営業'>
                            車の買取、販売スタッフを募集しております。
                        </Items>
                    </button>
                ):(
                    <button className="p-8 bg-white border rounded shadow-sm hover:border-rosso" onKeyDown={()=>setSelectItem(1)} onClick={()=>setSelectItem(1)}>
                        <Items title='自動車買取販売営業'>
                            車の買取、販売スタッフを募集しております。
                        </Items>
                    </button>
                )}
            </div>
            <div>
                <Detail data={Data[selectItem]}/>
            </div>
            <div className="mb-10 text-center md:mb-16 lg:mb-20">
                <a
                href="/form"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-rosso hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                応募はこちらから
                </a>
          </div>
        </div>
    );
};