import React from 'react'

export const Feature = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
        <div className="p-8 border-b sm:border-r">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" 
                className="w-8 h-8 text-rosso"
                stroke="currentColor"
                fill='currentColor'
                >
                <path d="M330.609,46.469V0H181.391v46.469h-80.75V512h113.125v-84h84.453v84h113.141V46.469H330.609z M172.578,373.875
                  h-33.359v-54.219h33.359V373.875z M172.578,264.375h-33.359v-54.219h33.359V264.375z M172.578,154.906h-33.359v-54.203h33.359
                  V154.906z M239.313,373.875h-33.359v-54.219h33.359V373.875z M239.313,264.375h-33.359v-54.219h33.359V264.375z M239.313,154.906
                  h-33.359v-54.203h33.359V154.906z M306.047,373.875h-33.359v-54.219h33.359V373.875z M306.047,264.375h-33.359v-54.219h33.359
                  V264.375z M306.047,154.906h-33.359v-54.203h33.359V154.906z M372.781,373.875h-33.375v-54.219h33.375V373.875z M372.781,264.375
                  h-33.375v-54.219h33.375V264.375z M372.781,154.906h-33.375v-54.203h33.375V154.906z"></path>
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">社名</h6>
            <p className="mb-3 text-sm text-gray-900">
              ACE Group
            </p>
          </div>
        </div>
        <div className="p-8 border-b lg:border-r">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg 
                className="w-8 h-8 text-rosso"
                stroke="currentColor"
                fill='currentColor' 
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path class="st0" d="M424.469,213.194c-45.5-63.719-102.688-64.125-168.469-32.359c-65.797-31.766-122.984-31.359-168.469,32.359
                  C43.75,274.459,48.125,291.959,0,324.053c42.094,33.656,197.453,61.859,256-73.594c58.531,135.453,213.906,107.25,256,73.594
                  C463.859,291.959,468.234,274.459,424.469,213.194z"></path>
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">代表</h6>
            <p className="mb-3 text-sm text-gray-900">
              浜津凱歌
            </p>
          </div>
        </div>
        <div className="p-8 border-b sm:border-r lg:border-r-0">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg
              className="w-8 h-8 text-rosso"
                stroke="currentColor"
                fill='currentColor'
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
                <path  d="M383.026,301.209c-15.786,55.296-66.665,95.798-127.036,95.798c-60.361,0-111.241-40.502-127.017-95.798H0
                  v158.56h512v-158.56H383.026z" ></path>
                <rect x="33.028" y="250.419"  width="445.934" height="22.306" ></rect>
                <path  d="M352.152,299.97H159.857c2.872,7.986,6.738,15.461,11.4,22.297h169.447
                  C345.415,315.431,349.281,307.956,352.152,299.97z" ></path>
                <path  d="M313.36,349.522H198.599c16.554,11.242,36.421,17.743,57.381,17.743
                  C276.94,367.265,296.808,360.764,313.36,349.522z" ></path>
                <rect x="33.028" y="200.877"  width="445.934" height="22.298" ></rect>
                <rect x="33.028" y="151.335"  width="445.934" height="22.287" ></rect>
                <rect x="33.028" y="101.783"  width="445.934" height="22.287" ></rect>
                <rect x="33.028" y="52.231"  width="445.934" height="22.298" ></rect>
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">事業内容</h6>
            <p className="mb-3 text-sm text-gray-900">
              配送業、自動車販売・買取
            </p>
          </div>
        </div>
        <div className="p-8 border-b lg:border-b-0 lg:border-r">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg 
                className="w-8 h-8 text-rosso"
                stroke="currentColor"
                fill='currentColor'
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path  d="M52.963,0v512h176.552v-70.621v-52.966h52.965v52.966V512h176.556V0H52.963z M176.55,441.379h-52.966v-52.966
                h52.966V441.379z M176.55,335.448h-52.966v-52.966h52.966V335.448z M176.55,229.517h-52.966v-52.966h52.966V229.517z
                M176.55,123.586h-52.966V70.621h52.966V123.586z M282.48,335.448h-52.965v-52.966h52.965V335.448z M282.48,229.517h-52.965
                v-52.966h52.965V229.517z M282.48,123.586h-52.965V70.621h52.965V123.586z M388.416,441.379h-52.97v-52.966h52.97V441.379z
                M388.416,335.448h-52.97v-52.966h52.97V335.448z M388.416,229.517h-52.97v-52.966h52.97V229.517z M388.416,123.586h-52.97V70.621
                h52.97V123.586z"></path>
            </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">所在地</h6>
            <p className="mb-3 text-sm text-gray-900">
              愛知県名古屋市
            </p>
          </div>
        </div>
        <div className="p-8 border-b sm:border-b-0 sm:border-r">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"  className="w-8 h-8 text-rosso"
                stroke="currentColor" fill='currentColor'>
                <path d="M510.746,110.361c-2.128-10.754-6.926-20.918-13.926-29.463c-1.422-1.794-2.909-3.39-4.535-5.009
                  c-12.454-12.52-29.778-19.701-47.531-19.701H67.244c-17.951,0-34.834,7-47.539,19.708c-1.608,1.604-3.099,3.216-4.575,5.067
                  c-6.97,8.509-11.747,18.659-13.824,29.428C0.438,114.62,0,119.002,0,123.435v265.137c0,9.224,1.874,18.206,5.589,26.745
                  c3.215,7.583,8.093,14.772,14.112,20.788c1.516,1.509,3.022,2.901,4.63,4.258c12.034,9.966,27.272,15.45,42.913,15.45h377.51
                  c15.742,0,30.965-5.505,42.967-15.56c1.604-1.298,3.091-2.661,4.578-4.148c5.818-5.812,10.442-12.49,13.766-19.854l0.438-1.05
                  c3.646-8.377,5.497-17.33,5.497-26.628V123.435C512,119.06,511.578,114.649,510.746,110.361z M34.823,99.104
                  c0.951-1.392,2.165-2.821,3.714-4.382c7.689-7.685,17.886-11.914,28.706-11.914h377.51c10.915,0,21.115,4.236,28.719,11.929
                  c1.313,1.327,2.567,2.8,3.661,4.272l2.887,3.88l-201.5,175.616c-6.212,5.446-14.21,8.443-22.523,8.443
                  c-8.231,0-16.222-2.99-22.508-8.436L32.19,102.939L34.823,99.104z M26.755,390.913c-0.109-0.722-0.134-1.524-0.134-2.341V128.925
                  l156.37,136.411L28.199,400.297L26.755,390.913z M464.899,423.84c-6.052,3.492-13.022,5.344-20.145,5.344H67.244
                  c-7.127,0-14.094-1.852-20.142-5.344l-6.328-3.668l159.936-139.379l17.528,15.246c10.514,9.128,23.922,14.16,37.761,14.16
                  c13.89,0,27.32-5.032,37.827-14.16l17.521-15.253L471.228,420.18L464.899,423.84z M485.372,388.572
                  c0,0.803-0.015,1.597-0.116,2.304l-1.386,9.472L329.012,265.409l156.36-136.418V388.572z" ></path>
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">メールアドレス</h6>
            <a href="mailto:acegroup-main@outlook.jp" className="mb-3 text-sm font-semibold text-rosso">
              acegroup-main@outlook.jp
            </a>
          </div>
        </div>
        <div className="p-8">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg
              className="w-8 h-8 text-rosso"
                stroke="currentColor" fill='currentColor'
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M94.811,21.696c-35.18,22.816-42.091,94.135-28.809,152.262c10.344,45.266,32.336,105.987,69.42,163.165
                  c34.886,53.79,83.557,102.022,120.669,129.928c47.657,35.832,115.594,58.608,150.774,35.792
                  c17.789-11.537,44.218-43.058,45.424-48.714c0,0-15.498-23.896-18.899-29.14l-51.972-80.135
                  c-3.862-5.955-28.082-0.512-40.386,6.457c-16.597,9.404-31.882,34.636-31.882,34.636c-11.38,6.575-20.912,0.024-40.828-9.142
                  c-24.477-11.262-51.997-46.254-73.9-77.947c-20.005-32.923-40.732-72.322-41.032-99.264c-0.247-21.922-2.341-33.296,8.304-41.006
                  c0,0,29.272-3.666,44.627-14.984c11.381-8.392,26.228-28.286,22.366-34.242l-51.972-80.134c-3.401-5.244-18.899-29.14-18.899-29.14
                  C152.159-1.117,112.6,10.159,94.811,21.696z"></path>
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">電話番号</h6>
            <a href="tel:090-8471-8964" className="mb-3 text-sm text-rosso font-semibold">
              090-8471-8964
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};