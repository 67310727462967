import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Feature } from "../components/Feature"
import Layout from "../components/layout"
import { Recruit } from "../components/Recruit"
import Seo from "../components/seo"

const Index = ({ data, location }) => {

  return (
    <Layout title="ACE Group" location={location}>
      <Seo title="ACE Group" />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <main className="pt-16 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">UPDATING THE</span>{' '}
                  <span className="block text-rosso xl:inline">LOGISTICS</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  信頼のネットワークで、<br/>
                  東海の物流を<br/>
                  進化へ導く。
                </p>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src="../images/TOP.jpg"
            alt="ACEGROUP"
          />
        </div>
      </div>
      <Feature/>
      <Recruit/>
    </Layout>
  )
}

export default Index