import React from 'react'

export const Feature = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col lg:flex-row">
        <div className="max-w-xl pr-16 mb-10">
          <h5 className="mb-6 text-3xl font-extrabold leading-none">
            事業内容
          </h5>
        </div>
        <div className="grid gap-5 row-gap-5 sm:grid-cols-2">
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
              <svg className="w-12 h-12 text-rosso"
                stroke="currentColor" fill='currentColor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M499.29,82.175H214.519c-7.022,0-12.71,6.024-12.71,13.452v200.679c0,7.428,5.688,13.434,12.71,13.434H499.29
                  c7.022,0,12.71-6.005,12.71-13.434V95.627C512,88.199,506.312,82.175,499.29,82.175z"></path>
                <path d="M102.781,354.37c-19.705,0-35.692,16.878-35.692,37.723c0,20.836,15.987,37.732,35.692,37.732
                  c19.697,0,35.665-16.896,35.665-37.732C138.446,371.248,122.478,354.37,102.781,354.37z"></path>
                <path d="M501.41,325.55H197.455c-5.847,0-10.599-5.026-10.599-11.208v-167.32c0-6.192-4.734-11.208-10.599-11.208
                  H79.711c-3.144,0-6.12,1.483-8.126,4.027L2.455,227.573C0.866,229.578,0,232.122,0,234.737v76.955v40.24
                  c0,6.183,4.743,11.199,10.59,11.199h53.267c8.629-12.93,22.85-21.418,38.924-21.418c16.066,0,30.278,8.488,38.916,21.418h217.407
                  c8.629-12.93,22.84-21.418,38.916-21.418c16.074,0,30.276,8.488,38.915,21.418h64.476c5.847,0,10.59-5.016,10.59-11.199v-15.174
                  C512,330.567,507.257,325.55,501.41,325.55z M38.703,243.648v-7.754c0-2.65,0.856-5.22,2.438-7.296l44.126-57.463
                  c2.085-2.702,5.193-4.265,8.479-4.265h43.234c6.041,0,10.934,5.166,10.934,11.543v65.236c0,6.377-4.894,11.544-10.934,11.544H49.62
                  C43.596,255.192,38.703,250.025,38.703,243.648z"></path>
                <path d="M398.02,354.37c-19.705,0-35.674,16.878-35.674,37.723c0,20.836,15.97,37.732,35.674,37.732
                  c19.696,0,35.673-16.896,35.673-37.732C433.693,371.248,417.715,354.37,398.02,354.37z"></path>
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">運送事業</h6>
            <p className="text-sm text-gray-700">
                センターに届いた荷物をお客様の指定した場所まで運ぶ簡単なお仕事です！<br/>
                研修制度もあるので初心者の方でも安心して働くことが可能です！<br/>
                自分だけの空間でお仕事したい方、高収入を目指したい方には是非オススメのお仕事になります。<br/>
                仕事エリアは愛知、岐阜エリアを設けております。<br/>
                ご自宅から近いエリアでもお仕事が可能です。<br/>
                事業拡大につき人員大募集を行っております。<br/>
                お気軽にご連絡ください！
            </p>
          </div>
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-12 h-12 text-rosso"
                stroke="currentColor" fill='currentColor'>
                <path d="M500.325,211.661l-34.024-54.143c-11.508-18.302-31.61-29.402-53.216-29.402H254.645
                  c-26.654,0-52.195,10.719-70.849,29.745l-45.216,46.107L30.738,228.933C12.733,233.11,0,249.147,0,267.615v42.348
                  c0,9.122,7.406,16.538,16.538,16.538h57.336c-0.074,1.141-0.185,2.274-0.185,3.425c0,29.8,24.167,53.958,53.977,53.958
                  c29.792,0,53.958-24.158,53.958-53.958c0-1.151-0.111-2.284-0.185-3.425h169.67c-0.074,1.141-0.185,2.274-0.185,3.425
                  c0,29.8,24.166,53.958,53.958,53.958c29.81,0,53.958-24.158,53.958-53.958c0-1.151-0.092-2.284-0.166-3.425h36.789
                  c9.132,0,16.538-7.416,16.538-16.538v-57.81C512,237.824,507.954,223.801,500.325,211.661z M127.666,351.43
                  c-11.879,0-21.494-9.643-21.494-21.504c0-11.871,9.615-21.495,21.494-21.495c11.86,0,21.494,9.624,21.494,21.495
                  C149.16,341.786,139.526,351.43,127.666,351.43z M264.13,215.754h-97.188l37.198-37.93c13.216-13.476,31.628-21.198,50.505-21.198
                  h9.486V215.754z M374.998,215.754h-85.94v-59.128h85.94V215.754z M404.882,351.43c-11.86,0-21.494-9.643-21.494-21.504
                  c0-11.871,9.634-21.495,21.494-21.495c11.879,0,21.494,9.624,21.494,21.495C426.376,341.786,416.761,351.43,404.882,351.43z
                  M399.944,215.754v-59.128h13.142c11.879,0,22.756,6.004,29.067,16.065l27.062,43.063H399.944z"></path>
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">自動車販売・買取事業</h6>
            <p className="text-sm text-gray-700">
              国産車から外国車、スーパーカーまで幅広く取り扱っております。<br/>
              品質に徹底しており、納車後も安心してカーライフを楽しんで頂けるよう努めております！<br/>
              買取・販売・整備などクルマの事なら何でもご相談ください！
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};