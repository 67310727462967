import React from 'react'
import { FirstView } from '../components/about/FirstView'
import Layout from '../components/layout'
import Seo from '../components/seo'

const FormPage = ({ data, location }) => {
    return (
        <Layout title="ACE Group" location={location}>
            <Seo title="採用フォーム" />
            <FirstView title='採用フォーム'/>
            <form 
            action="https://docs.google.com/forms/u/3/d/e/1FAIpQLSe8VVLN-uy0yR7a6a4mCnfeUQe-IquOJ16wLmymZK_Np__lRw/formResponse"
            className='px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8'>
                <div class="relative pb-4">
                    <label for="name">
                        名前
                        <span className="text-rosso required-dot">
                            *
                        </span>
                    </label>
                    <input type="text" required id="name" className="rounded-lg flex-1 appearance-none border border-gray w-full py-2 px-4 bg-white  shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="entry.497966697"/>
                </div>
                <div class="relative pb-4">
                    <label for="old">
                        年齢
                        <span className="text-rosso required-dot">
                            *
                        </span>
                    </label>
                    <input type="text" required id="old" className="rounded-lg flex-1 appearance-none border border-gray w-full py-2 px-4 bg-white  shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="entry.1350392307"/>
                </div>
                <div class="relative pb-4">
                    <label for="tel">
                        電話番号
                        <span className="text-rosso required-dot">
                            *
                        </span>
                    </label>
                    <input type="tel" required id="tel" className="rounded-lg flex-1 appearance-none border border-gray w-full py-2 px-4 bg-white  shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="entry.1938748812"/>
                </div>
                <div class="relative pb-4">
                    <label for="email">
                        Email
                        <span className="text-rosso required-dot">
                            *
                        </span>
                    </label>
                    <input type="email" required id="email" className="rounded-lg flex-1 appearance-none border border-gray w-full py-2 px-4 bg-white  shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="entry.1019073877"/>
                </div>
                <div class="relative pb-4">
                    <label for="address">
                        住所（市区町村まで）
                        <span className="text-rosso required-dot">
                            *
                        </span>
                    </label>
                    <input type="text" required id="address" className="rounded-lg flex-1 appearance-none border border-gray w-full py-2 px-4 bg-white  shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="entry.1846594912"/>
                </div>
                <div class="relative pb-4">
                    <label for="reason">
                        志望動機
                        <span className="text-rosso required-dot">
                            *
                        </span>
                    </label>
                    <textarea type="text" required id="reason" className="rounded-lg flex-1 appearance-none border border-gray w-full py-2 px-4 bg-white  shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="entry.2128684791">

                    </textarea>
                </div>
                <input type='submit' className="cursor-pointer inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-rosso focus:shadow-outline focus:outline-none"/>
            </form>
        </Layout>
    )
}

export default FormPage