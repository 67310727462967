import React from 'react'
import { FirstView } from '../components/about/FirstView'
import Layout from '../components/layout'
import { Faq } from '../components/Recruit/Jobs'
import { Step } from '../components/Recruit/Step'
import Seo from '../components/seo'

const RecruitPage = ({ data, location }) => {
    return (
        <Layout title="ACE Group" location={location}>
            <Seo title="採用情報" />
            <FirstView title='採用情報'/>
            <Step/>
            <Faq />
        </Layout>
    )
}

export default RecruitPage