import React from 'react'

export const FirstView = ({title}) => {
  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="flex flex-col sm:text-center sm:mb-0">
          <div className="mx-auto text-center lg:max-w-2xl ">
            <h2 className="max-w-lg font-sans text-3xl font-bold leading-none tracking-tight text-rosso sm:text-4xl md:mx-auto">
              {title}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};