import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'

export const Recruit = () => {
  return (
    <div className="relative flex flex-col-reverse py-16 lg:py-0 lg:flex-col">
      <div className="w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:py-20 lg:max-w-screen-xl">
        <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:text-center">
            私たちと共に
            <br className="hidden md:block" />
            働きませんか？
          </h2>
          <p className="mb-5 text-base text-gray-700 md:text-lg md:text-center">
            チャレンジングな環境に身を置き<br/>
            自身をさらに成長させていきたいという<br/>
            新しい仲間を募集しています。
          </p>
          <div className="mb-10 text-center md:mb-16 lg:mb-20">
            <a
              href="/recruit"
              className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-rosso hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              詳細はこちらから
            </a>
          </div>
        </div>
      </div>
      <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
        <StaticImage
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src="../../images/recruit.jpg"
            alt="recruit"
          />
      </div>
    </div>
  );
};